<template>
  <a-spin :spinning="false">
    <div class="questions-count-box">
      <div class="questions-count-header" >
        <a-row >
          <a-col :span="6"><div class="my-result" @click="gotodetail">
            {{ $t("quesition.my_result") }}
          </div></a-col>
          <a-col :span="12"><h1 style="font-size: 2em;font-weight: 700;text-align: center;">{{ pageName || dataInfo.questionnaireName }}</h1></a-col>
          <a-col :span="6" style="text-align: right;"></a-col>
        </a-row>
        <a-row >
          <a-col :span="24"><p class="questions-count-header-intro">{{ dataInfo.intro }}</p></a-col>
        </a-row>

      </div>

      <div class="content">
        <div class="questions-count-content">
          <!--单选题-->
          <template v-for="(item, index) in quesData" :key="index">
            <div class="qccr-title">
              <div class="qccr-title-label">[{{getQuestionType(item.questionType)}}]</div>
              <div class="qccr-title-value">{{index+1}}、{{item.title}}</div>
              <div class="qccr-media" >
                <span class="qccr-media-item" v-for="(mv,mi) in item.titimgs" :key="'media'+mi" ><a-button type="link" class="blod-text-tag"  @click="showMedia(mv)">[{{$t(getResourceTypeStr(mv))}}]</a-button></span>
              </div>
            </div>

            <div class="questions-count-content-row" v-if="item.questionType == 1 || item.questionType == 2 || item.questionType == 9 || item.questionType == 10">
              <a-table :dataSource="item.scoreList" :columns="allColumns[index]" className="questions-count-content-table" rowKey="index" bordered :pagination="false" >
                <template #title_option >{{ $t('LB_Exam_Option') }}</template>
                <template #title_vote >{{ $t('LB_Exam_TheVote') }}</template>
                <template #title_ratio >{{ $t('LB_Exam_TheRatio') }}</template>
                <template #theOption = "{record,index}">
                  <div v-if="record.media!=''"> <a-button type="link" class="blod-text-tag" @click="showMedia(record.media)">[{{ $t(getResourceTypeStr(record.media))}}]</a-button></div>
                  <div v-else>{{record.option}}</div>
                </template>
                <template #theVote = "{record,index}">
                  {{item.scoreRange[index]}}
                </template>
                <template #theRatio = "{record,index}">
                  {{statisticsResults(item.scoreRange[index])}}%
                </template>
              </a-table>
            </div>
            <!-- 区块2 -->
            <div class="questions-count-content-row" v-if="item.questionType == 5" >
              <div v-for="(aitem,aindex) in item.analysis">
                <div class="easy-answer-row">{{aindex+1}}、{{aitem.realName}}({{aitem.account}})：{{aitem.answer}}</div>
              </div>
            </div>
            <div class="questions-count-content-row" v-if="item.questionType == 6 || item.questionType==7" >
              <a-table :dataSource="[{questionType:item.questionType}]" :columns="allColumns[index]" className="questions-count-content-table" rowKey="index" bordered :pagination="false" >
                <template #title_AverageScore >{{ $t('homework.AverageScore') }}</template>
              </a-table>
            </div>
            <!-- 区块3 -->
            <div class="questions-count-content-row" v-if="item.questionType == 11">
              <a-table :dataSource="item.scoreList" :columns="allColumns[index]" className="questions-count-content-table" rowKey="index" bordered :pagination="false" >
                <template #title_option >{{ $t('page.option') }}</template>
                <template #title_AverageScore >{{ $t('homework.AverageScore') }}</template>
              </a-table>
            </div>


          </template>
        </div>
      </div>
    </div>
    <a-modal v-model:visible="mediaModal" :title="mediaTitle" :width="900" :footer="null">
      <div style="text-align: center;margin-top: 30px;">
        <div v-if="mediaImage!=''" ><a-image
            style="max-width: 800px;"
            :src="mediaImage" /></div>
        <div v-if="mediaAudio!=''"> <!-- 显示音频录音 -->
          <audio controls style="width: 800px;">
            <source :src="mediaAudio" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio></div>
        <div v-if="mediaVideo!=''">
          <!-- 显示视频录制 -->
          <video width="800" controls>
            <source :src="mediaVideo" type="video/mp4">
            Your browser does not support the video tag.
          </video>
        </div>


      </div>
    </a-modal>
  </a-spin>
</template>

<script>
import { ref ,reactive ,toRefs} from "vue";
import { useRouter, useRoute } from "vue-router";
import { trackQuestionnaireAnalysis } from "@/api/train.js";
import OpenData from "@/components/OpenData.vue";
import { useI18n } from 'vue-i18n';

export default {
  components: {
    OpenData,
  },
  setup() {

    const state = reactive({
      mediaModal:false,
      mediaImage:"",
      mediaVideo:"",
      mediaAudio:"",
      mediaTitle:""
    });
    const { t} = useI18n();
    const route = useRoute();
    const router = useRouter();
    const spinning = ref(false);
    const dataInfo = ref({});
    const quesData = ref([]);
    const allColumns = reactive({});
    const taskId = route.query.taskId;
    const detailId = route.query.detailId;
    const questionnaireId = route.query.questionnaireId;
    const pageName = route.query.name;


    const userCount = ref(0);
    const gotodetail = () => {
      router.back();
    };

    const getSliderMarks = (que) => {
      if (que.questionType == 7) {
        let score = que.settingObj.score;
        let startValue = que.settingObj.startValue;
        let arr = {};
        for (let i = 1; i <= (score == 100 ? 10 : score); i++) {
          if (score == 100) {
            arr[i] = startValue + i * 10 - 10;
          } else {
            arr[i] = startValue + i - 1;
          }
        }
        return arr;
      } else {
        let score = que.settingObj.score;
        let arr = {};
        for (let i = 1; i <= score; i++) {
          if (score == 100) {
            if (i % 10 == 0) arr[i] = i;
          } else {
            arr[i] = i;
          }
        }
        return arr;
      }
    };

    const getTypeList = () =>{
      return ["","LB_Exam_Radio","LB_Exam_MultiSelect","","","CM_QA","LB_Exam_RatingQuestion","LB_Exam_ScaleQuestions","","LB_Exam_PictureSingleChoice","LB_Exam_PictureMultipleChoice","LB_Exam_CombinationEvaluationQuestion"];
    };

    const getTypeOne = (type) =>{
      let typeList = getTypeList();
      return typeList[type];
    };
    const getQuestionType = (type) =>{
      let str = t(getTypeOne(type));
      return str;
    };

    const CreatePiceBtn = (url) =>{
      let text = t(getResourceTypeStr(url));
      return '';
    };

    const setAllColumns = (quesData) => {
      let typeList = getTypeList()
      let columns = [];
      quesData.value.forEach( (info,info_index) =>{
        var typename = typeList[info.questionType];
        if(typename=="LB_Exam_Radio" || typename=='LB_Exam_MultiSelect' || typename=='LB_Exam_PictureSingleChoice' || typename=='LB_Exam_PictureMultipleChoice'){
          columns = [{
            dataIndex:'option',
            slots: { title: 'title_option',customRender:'theOption'},
            i18nTitle:'page.option',
            align: 'left',
            fixed: false,
            sorter: false
          },{
            dataIndex:'TheVote',
            i18nTitle:'page.TheVote',
            slots: { title: 'title_vote',customRender:'theVote'},
            align: 'left',
            fixed: false,
            sorter: false,
          },{
            dataIndex:'TheRatio',
            i18nTitle:'page.TheRatio',
            slots: { title: 'title_ratio',customRender:'theRatio'},
            align: 'left',
            fixed: false,
            sorter: false
          }];
          allColumns[info_index] = columns;
        }
        else if(typename=='LB_Exam_RatingQuestion' || typename=='LB_Exam_ScaleQuestions'){
          let columns = [];
          let avgnum = 0;

          let scores = getSliderMarks(info);
          let numberArr = [];
          for(let index in scores ){
            let item = scores[index];
            numberArr.push(item);
          }
          numberArr.forEach((item,index) =>{
            avgnum += (info.scoreRange[index]==0?0:(info.scoreRange[index]*item));
          })
          avgnum = info.analysis.length==0?0:avgnum/info.analysis.length;
          columns.unshift({
            dataIndex:'AverageScore',
            i18nTitle:'page.AverageScore',
            slots: { title: 'title_AverageScore'},
            align: 'center',
            fixed: false,
            sorter: false,
            customRender: ({ record, index }) => {
              return {
                children:avgnum.toFixed(1),
              }
            }
          });

          numberArr.forEach((item,index) =>{
            let num = info.scoreRange[index];
            let rate = info.analysis.length==0?0:num/info.analysis.length;
            rate = num+"("+(Math.round(rate*100))+"%)";
            columns.unshift({
              title:item+"",
              align: 'left',
              fixed: false,
              sorter: false,
              customRender: ({ record }) => {
                return {
                  children:rate
                }
              }
            });
          })

          allColumns[info_index] = columns;
        }
        else if(typename == 'LB_Exam_CombinationEvaluationQuestion'){
          let columns = [];
          let avgnum = 0;
          info.scores.forEach((item,index) =>{
            avgnum += (info.scoreRange[index]==0?0:item);
          })
          avgnum = info.analysis.length==0?0:avgnum/info.analysis.length;
          columns.unshift({
            dataIndex:'AverageScore',
            i18nTitle:'page.AverageScore',
            slots: { title: 'title_AverageScore'},
            align: 'center',
            fixed: false,
            sorter: false,
            children: [
              {
                align: 'center',
                colSpan:0,
                customRender: ({ record, index }) => {
                  return {
                    children:avgnum,
                  }
                }
              },
              {
                colSpan:0,
                align: 'center',
                customRender: ({ record, index }) => {
                  return {
                    children:avgnum,
                    props:{
                      rowSpan:index==0?info.scoreList.length:0,
                      style:{
                        verticalAlign:'middle'
                      }
                    },

                  }
                }
              },
            ],

          });
          for(let i=0;i<5;i++) {
            let num = 0;
            let rate = info.analysis.length==0?0:num/info.analysis.length;
            rate = num+"("+(Math.round(rate*100))+"%)";
            columns.unshift({
              title: "-",
              align: 'left',
              fixed: false,
              sorter: false,
              customRender: ({ record }) => {
                return {
                  children: rate
                }
              }
            });
          }

          columns.unshift({
            dataIndex:'option',
            slots: { title: 'title_option'},
            i18nTitle:'page.option',
            align: 'left',
            fixed: false,
            sorter: false,
            customRender: ({ record, index }) => {
              return {
                children:record.option
              }
            }
          });
          allColumns[info_index] = columns;
        }
      })

      console.log(allColumns);

    }

    const openModal = () => {
      spinning.value = false;
      dataInfo.value = [];
      quesData.value = [];
      const paermes = {
        taskId,
        detailId,
        questionnaireId,
      };
      trackQuestionnaireAnalysis(paermes).then((res) => {

        dataInfo.value = res.data;
        userCount.value = res.data.users;
        quesData.value = res.data.questions || [];

        quesData.value.map(item => {
          item.settingObj = JSON.parse(item.setting);
          item.titimgs = item.titimgs || [];
          item.scoreList = [];
          item.options = item.options || [];
          item.analysis = item.analysis || [];
          item.options.forEach((opt, i) => {
            item.scoreList.push({
              option: opt,
              score: 0,
              remark: item.remarks[i],
              media: item.opimgs[i],
              label: item.labels[i],
              // checked: item.analysis[i] > 0 ? true : false,
              // analysis: item.analysis[i] || 0,
            });
          });
          // 单选多选没有追评数据过滤
          if (item.questionType == 1 || item.questionType == 2) {
            item.analysisA = item.analysis.filter(a => a[`ps${item.options.length}`]);
          }
          // 计算评分题和量表题选项百分比
          // if (item.questionType == 6 || item.questionType == 7) {
          item.scoreRange = new Array(item.scores.length).fill(0);
          item.tempScores = item.scores.map((s, sI) => {
            return {
              score: s,
              i: sI + 1,
            };
          });;
          item.analysis.forEach(a => {
            item.scores.map((s, sI) => {
              a[`op${sI + 1}`] > 0 && item.scoreRange[sI]++;
            });
          });
        });
        console.log(quesData);
        setAllColumns(quesData);
        spinning.value = false;
      });
    };

    const getResourceType = (url) => {
      if (!url || url == "") {
        return "";
      }
      const imgFormat = ["jpg", "png", "gif"];
      const videoFormat = ["mp4"];
      const audioFormat = ["mp3"];
      let u = url.split(".");
      let suffix = u[u.length - 1].toLowerCase();
      let type = "";
      if (imgFormat.indexOf(suffix) >= 0) {
        type = "image";
      } else if (videoFormat.indexOf(suffix) >= 0) {
        type = "video";
      } else if (audioFormat.indexOf(suffix) >= 0) {
        type = "audio";
      }
      return type;
    };

    const getResourceTypeStr = (url) =>{
      let type = getResourceType(url);
      let arr = {image:"LB_Picture",video:"Pub_Video",audio:'LB_AudioFrequency'};
      return arr[type];
    }
    const formatter = (value) => {
      return `${value}%`;
    };

    const getColor = (index) => {
      if (index == 0) {
        return "#1890ff";
      } else if (index == 1) {
        return "#ff8500";
      } else if (index == 2) {
        return "#00bb9e";
      } else if (index == 3) {
        return "#0dcfe6";
      } else if (index == 4) {
        return "#f1582b";
      } else if (index == 5) {
        return "#4e23ed";
      } else if (index == 6) {
        return "#e42929";
      } else if (index == 7) {
        return "#f1ad00";
      } else if (index == 8) {
        return "#496df0";
      }
    };

    const statisticsResults = (analysis) => {
      return analysis > 0 ? ((analysis / userCount.value) * 100).toFixed(0) : 0;
    };

    const showMedia = url =>{
      let type = getResourceType(url);
      state.mediaImage = "";
      state.mediaVideo = "";
      state.mediaAudio = "";
      if(type == 'video'){
        state.mediaVideo = url;
      }else if(type == 'audio'){
        state.mediaAudio = url;
      }else{
        state.mediaImage = url;
      }
      state.mediaTitle =  t(getResourceTypeStr(url));
      state.mediaModal = true;
    };

    openModal();
    return {
      ...toRefs(state),
      userCount,
      pageName,
      gotodetail,
      taskId,
      detailId,
      questionnaireId,
      spinning,
      dataInfo,
      quesData,
      getResourceType,
      formatter,
      openModal,
      getColor,
      statisticsResults,
      allColumns,
      getQuestionType,
      getResourceTypeStr,
      showMedia
    };
  },
};
</script>
<style lang="less" scoped>
@import "~@/assets/style/common.less";
.questions-count-box{
  width:1000px;
  min-height: 220px;
  margin:0 auto;
  background: #fff;
  border:1px solid #f5f6f7;
  padding: 36px;
  .my-result{
    width: 118px;
    height: 34px;
    border: 1px solid var(--theme);
    font-size: 16px;
    color: var(--theme);
    text-align: center;
    line-height: 34px;
    cursor: pointer;
  }
  .questions-count-header-intro{
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    color:#999999;
  }
  .questions-count-content{
    width:98%;
    margin:0 auto;
  }
  .questions-count-header{
    margin:0 0 30px 0;
  }
  .questions-count-header-row{
    font-size: 12px;
    margin:4px 0;
  }
  .questions-count-content-row{
    margin-bottom: 20px;
  }
  .qccr-title{
    font-size: 14px;
    font-weight: 700;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
  }
  .qccr-title-label{margin-right: 8px;}
  .qchr-item{
    display: flex;
    align-items: center;
  }
  ::v-deep .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
    padding: 10px 10px;
    overflow-wrap: break-word;
    font-size: 12px;
    font-weight: 700;
  }
  ::v-deep .ant-table-tbody .ant-table-row td {
    padding: 10px 10px;
    font-size: 12px;
  }
  ::v-deep .blod-text-tag{
    font-size: 12px;
    font-weight: 700;
    margin:0;
    padding:0;
  }
  .easy-answer-row{
    font-size: 14px;
    font-weight: 700;
    padding:6px ;
    line-height: 22px;

  }
  .qccr-media-item{
    padding: 0 10px;
  }
}
</style>
